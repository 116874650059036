import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useParams, useLocation, useHistory } from 'react-router-dom';
import { useAsyncRun, useAsyncTaskAxios, useAsyncTaskTimeout } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { useDebounce } from 'use-lodash-debounce';
import {
  Session,
  Filter,
  ReportGeneratorStatus,
  CentreHoldStatus,
} from '@cambridgeassessment/checkpoint-dtos';
import { getQualification } from '@cambridgeassessment/checkpoint-utils';
import { Breadcrumbs, Button } from '@cambridgeassessment/cambridge-ui';
import { Box, Container, IconButton, Link, Tabs, Typography } from '@material-ui/core';
import DialogWindow from '../components/Dialog/Dialog';
import MoreActionMenu from '../components/MoreActionMenu/MoreActionMenu';
import { useCentreStats } from '../utils/useCentreStats';
import Table, { TableDataRow } from '../components/Table/Table';
import SearchInput from '../components/SearchInput/SearchInput';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import { PageLoader } from '../components/PageLoader';
import NoResultsView, { Error } from '../components/NoResultsView/NoResultsView';
import { FilterTab } from '../components/Tabs/FilterTab';
import { FilterTabLabel } from '../components/Tabs/FilterTabLabel';
import { ReactComponent as IconPdf } from '../icons/iconPdf.svg';
import { ReactComponent as IconXls } from '../icons/iconXls.svg';
import { ReportViewerModal } from '../components/ReportViewer/ReportViewerModal';
import HoldOptions from '../components/HoldOptions/HoldOptions';
import DownloadS3FileButton from '../components/DownloadS3FileButton';
import { contains } from '../utils/helpers';
import Loader from '../components/Loader/Loader';
import { LabelWithIndicator } from '../components/LabelWithIndicator';

export interface SessionQualPath {
  id: string;
  qid: string;
}

const pollInterval = 15000;

const getFilterLabel = (filter?: Filter): string => {
  if (filter === 'complete') {
    return 'Complete';
  }
  if (filter === 'incomplete') {
    return 'Incomplete';
  }
  if (filter === 'absent') {
    return 'With Absents';
  }
  if (filter === 'missing') {
    return 'Missing';
  }
  return 'All Centres';
};

export const SessionQualificationProgressPage = ({
  sessions,
}: {
  sessions: Session[];
}): JSX.Element => {
  const config = useApplicationEnv();
  const { id, qid } = useParams<SessionQualPath>();
  const location = useLocation();
  const history = useHistory();
  const [flipFlop, setFlipFlop] = useState(false);
  const [reportGeneratorStatus, setReportGeneratorStatus] = useState<ReportGeneratorStatus>();
  const [openConfirmOneCentreModal, setConfirmOneCentreOpen] = useState(false);
  const [holdOrReleaseModal, setHoldOrReleaseModal] = useState(false);
  const [holdOrReleaseUpdates, setHoldOrReleaseUpdates] = useState<Record<string, string>>({});
  const [selectedCentre, setSelectedCentre] = useState<string | undefined>(undefined);
  const [holdCompleteModal, setHoldCompleteModal] = useState(false);

  const useQuery = (): URLSearchParams => new URLSearchParams(location.search);

  const query = useQuery();

  const generateReportMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/generateReport`,
      method: 'POST',
    };
  }, [config]);

  const generateReportTask = useAsyncTaskAxios<
    AxiosResponse<{ executionArn: string; startDate: string }>
  >(axios, generateReportMemo);

  const reportGeneratorStatusMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/reportGeneratorStatus`,
    };
  }, [flipFlop, config]);

  const reportGeneratorStatusTask = useAsyncTaskAxios<AxiosResponse<ReportGeneratorStatus>>(
    axios,
    reportGeneratorStatusMemo
  );

  const pollForProgress = useCallback(() => {
    setFlipFlop(!flipFlop);
  }, [flipFlop]);

  useEffect(() => {
    setFlipFlop(!flipFlop);
  }, [generateReportTask.result]);

  const progressPollTimeout = useAsyncTaskTimeout(pollForProgress, pollInterval);

  useAsyncRun(reportGeneratorStatusTask);
  useAsyncRun(progressPollTimeout);
  const [reportGeneratorRunning, setReportGeneratorRunning] = useState(false);

  useEffect(() => {
    if (reportGeneratorStatusTask.result?.data.sfStatus === 'STARTED' && !reportGeneratorRunning) {
      setReportGeneratorRunning(true);
    }
    if (generateReportTask.started) {
      setReportGeneratorStatus(undefined);
    } else if (
      reportGeneratorStatusTask.result?.data &&
      (!generateReportTask.result ||
        reportGeneratorStatusTask.result?.data.executionStart >=
          generateReportTask.result.data.startDate)
    ) {
      setReportGeneratorStatus(reportGeneratorStatusTask.result?.data);
    }
  }, [reportGeneratorStatusTask.result, generateReportTask.started]);

  useEffect(() => {
    if (generateReportTask.started) {
      setReportGeneratorRunning(true);
    }
  }, [generateReportTask.started]);

  useEffect(() => {
    if (
      (reportGeneratorStatusTask.result?.data?.sfStatus === 'COMPLETE' ||
        reportGeneratorStatusTask.result?.data?.sfStatus === 'FAILED') &&
      reportGeneratorStatusTask.result?.data?.executionId ===
        generateReportTask.result?.data.executionArn
    ) {
      setReportGeneratorRunning(false);
    }
  }, [reportGeneratorStatusTask.result, generateReportTask.result]);

  const filter = useMemo<Filter | undefined>(() => {
    const filterQuery = query.get('filter');

    if (!filterQuery) {
      return undefined;
    }

    if (Array.isArray(filterQuery)) {
      return filterQuery[0] as Filter;
    }

    return filterQuery as Filter;
  }, [location]);

  const { filtered, task, totalCount, totalEntryCount, centreSummaryStats } = useCentreStats({
    sessionId: id,
    qualificationId: qid,
    filter,
  });

  const searchQuery = query.get('search') || '';
  const debouncedQuery = useDebounce(searchQuery, 300);
  const [selectedKey, setSelectedKey] = useState<{ key: string; fileName: string } | undefined>(
    undefined
  );
  const [showViewer, setShowViewer] = useState(false);
  const reportFilePrefix = (centreNumber: string): string => `${id}-${qid}-${centreNumber}`;
  const showItem = (key: string, centreNumber: string): void => {
    setSelectedKey({ key, fileName: `${reportFilePrefix(centreNumber)}.pdf` });
    setShowViewer(true);
  };

  const toggle = (): void => {
    setShowViewer(!showViewer);
  };

  const getCentreHoldMemo = useMemo(() => {
    if (!config || !selectedCentre || !holdOrReleaseModal) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/centres/${selectedCentre}/getholdstatus`,
    };
  }, [config, selectedCentre, holdOrReleaseModal]);

  const getCentreHoldTask = useAsyncTaskAxios<AxiosResponse<CentreHoldStatus[]>>(
    axios,
    getCentreHoldMemo
  );

  const holdMemo = useMemo(() => {
    if (!config || !selectedCentre) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/centres/${selectedCentre}/holdlearner`,
      method: 'POST',
    };
  }, [config, selectedCentre]);

  const holdTask = useAsyncTaskAxios<AxiosResponse<string>>(axios, holdMemo);

  const confirmOneCentreModal = (centreId: string): void => {
    setSelectedCentre(centreId);
    setConfirmOneCentreOpen(true);
  };
  const handleCloseOneCentre = (): void => {
    setConfirmOneCentreOpen(false);
  };

  const confirmHoldCompleteModal = async (): Promise<void> => {
    setHoldOrReleaseModal(false);
    setHoldCompleteModal(true);
    await holdTask.start({
      data: {
        assessmentHold: Object.keys(holdOrReleaseUpdates).map((key: string) => ({
          assessmentCode: key,
          hold: holdOrReleaseUpdates[key] === 'hold',
        })),
      },
    });
  };

  const handleCloseHoldComplete = (): void => {
    setHoldOrReleaseUpdates({});
    setHoldCompleteModal(false);
  };

  const confirmHoldOrReleaseModal = (centreId: string): void => {
    setSelectedCentre(centreId);
    setHoldOrReleaseModal(true);
  };
  const handleCloseHoldOrRelease = (): void => {
    setHoldOrReleaseUpdates({});
    setHoldOrReleaseModal(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const tableData = useMemo(
    () =>
      filtered &&
      filtered
        .filter((item) => contains(item.name, debouncedQuery) || contains(item.id, debouncedQuery))
        .map((item) => ({
          ...item,
          hasHeldLearners: item.hasHeldLearners,
          issued: 'No data',
          name: item.name,
          generated:
            item.pdfReportKey || item.xlsxReportKey ? (
              <Box display="flex" my={-1.5} alignItems="center" justifyContent="center">
                {item.pdfReportKey !== undefined && (
                  <IconButton onClick={() => showItem(item.pdfReportKey || '', item.id)}>
                    <IconPdf />
                  </IconButton>
                )}
                {item.xlsxReportKey !== undefined && (
                  <DownloadS3FileButton
                    s3key={item.xlsxReportKey}
                    downloadFileName={`${reportFilePrefix(item.id)}.xlsx`}
                  >
                    <IconXls />
                  </DownloadS3FileButton>
                )}
              </Box>
            ) : (
              'No data'
            ),
          actions: (
            <MoreActionMenu
              reportGeneratorRunning={reportGeneratorRunning}
              onGenerateReport={() => {
                confirmOneCentreModal(item.id);
              }}
              onHoldOrRelease={() => {
                confirmHoldOrReleaseModal(item.id);
              }}
            />
          ),
        })),
    [filtered, debouncedQuery, reportGeneratorRunning]
  );

  const filterLabel = useMemo(() => getFilterLabel(filter), [filter]);

  const currentSession = useMemo(
    () => sessions.find((session) => String(session.id) === id)?.displayName,
    [id, sessions]
  );

  const handleSearch = (value: string): void => {
    const url = `/session/${id}/qualification/${qid}`;
    if (value) {
      history.push(`${url}?search=${value}`);
    } else {
      history.push(`${url}`);
    }
  };

  useAsyncRun(reportGeneratorStatusTask);
  useAsyncRun(progressPollTimeout);
  useAsyncRun(getCentreHoldTask);

  const headerColumns = useMemo(
    () => [
      {
        header: 'Centre name',
        accessor: 'name',
        renderCell: (row: TableDataRow) => (
          <Typography variant="subtitle2">
            <Link
              color="primary"
              component={RouterLink}
              to={`/session/${id}/qualification/${qid}/centre/${String(row.id)}`}
            >
              {row.name}
            </Link>
          </Typography>
        ),
      },
      {
        header: 'Centre #',
        accessor: 'id',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Entries',
        accessor: 'entryCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Marks',
        accessor: 'completeCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Absent',
        accessor: 'absentCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Missing',
        accessor: 'missingCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Hold',
        accessor: 'hasHeldLearners',
        renderCell: (row: TableDataRow) => (
          <>
            {row.hasHeldLearners && <LabelWithIndicator label="Learners withheld" />}
            {!row.hasHeldLearners && (
              <Typography variant="body1" color="textSecondary">
                Not withheld
              </Typography>
            )}
          </>
        ),
      },
      {
        header: 'Generated report',
        accessor: 'generated',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Issued report',
        accessor: 'issued',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Actions',
        accessor: 'actions',
      },
    ],
    []
  );

  return (
    <>
      <Box py={1.5} borderBottom={`4px solid ${getQualification(parseInt(qid, 10)).color}`}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h4" gutterBottom>
                Centres
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" gutter="sm">
                <Link component={RouterLink} to={`/session/${id}`}>
                  <span>{currentSession}</span>
                </Link>
                <Typography>{getQualification(parseInt(qid, 10)).name}</Typography>
              </Breadcrumbs>
            </Box>
            <Box ml="auto">
              {reportGeneratorRunning && (
                <ProgressBar
                  increment={
                    (reportGeneratorStatus?.centreProgress || 0) +
                    (reportGeneratorStatus?.errorCount || 0)
                  }
                  label="Generating reports..."
                  subLabel="generated"
                  total={reportGeneratorStatus?.centreTotal}
                />
              )}
              {!reportGeneratorRunning && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleClickOpen}
                  data-testid="buttonGenerateReport"
                >
                  Generate Series Reports
                </Button>
              )}
              <DialogWindow
                description="You are about to generate reports for all the complete centres in this series
                      within this qualification. This process is time consuming, the reports will
                      appear one by one once they are generated."
                onCloseDialog={handleClose}
                openDialog={open}
                title="Generate Series Reports"
                ConfirmButtonOnClick={async () => {
                  await generateReportTask.start();
                  handleClose();
                }}
                data-testid="DialogConfirmAllCentres"
                buttonDataTestId={generateReportTask.result?.data.executionArn}
                fixedWidth
              />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container maxWidth="lg">
          {task.started && task.pending && <PageLoader />}
          {task.error && <Error />}
          {filtered !== undefined && totalCount !== undefined && totalCount > 0 && (
            <Box mt="1rem" mb="2rem">
              <Tabs
                value={filter || 0}
                onChange={(
                  _event: React.ChangeEvent<Record<string, unknown>>,
                  newValue: Filter
                ) => {
                  history.push(
                    newValue
                      ? `/session/${id}/qualification/${qid}?filter=${newValue}`
                      : `/session/${id}/qualification/${qid}`
                  );
                }}
                indicatorColor="primary"
                textColor="primary"
                data-testid="filter-tabs"
              >
                {totalCount && totalEntryCount && (
                  <FilterTab
                    value={0}
                    label={
                      <FilterTabLabel
                        label={getFilterLabel()}
                        primaryCount={totalCount}
                        secondaryCount={totalEntryCount}
                      />
                    }
                  />
                )}
                {centreSummaryStats && centreSummaryStats.complete && (
                  <FilterTab
                    value="complete"
                    label={
                      <FilterTabLabel
                        label={getFilterLabel('complete')}
                        primaryCount={centreSummaryStats.complete.centreCount}
                        secondaryCount={centreSummaryStats.complete.entryCount}
                      />
                    }
                  />
                )}
                {centreSummaryStats && centreSummaryStats.incomplete && (
                  <FilterTab
                    value="incomplete"
                    label={
                      <FilterTabLabel
                        label={getFilterLabel('incomplete')}
                        primaryCount={centreSummaryStats.incomplete.centreCount}
                        secondaryCount={centreSummaryStats.incomplete.entryCount}
                      />
                    }
                  />
                )}
                {centreSummaryStats && centreSummaryStats.absent && (
                  <FilterTab
                    value="absent"
                    label={
                      <FilterTabLabel
                        label={getFilterLabel('absent')}
                        primaryCount={centreSummaryStats.absent.centreCount}
                        secondaryCount={centreSummaryStats.absent.entryCount}
                      />
                    }
                  />
                )}
                {centreSummaryStats && centreSummaryStats.missing && (
                  <FilterTab
                    value="missing"
                    label={
                      <FilterTabLabel
                        label={getFilterLabel('missing')}
                        primaryCount={centreSummaryStats.missing.centreCount}
                        secondaryCount={centreSummaryStats.missing.entryCount}
                      />
                    }
                  />
                )}
              </Tabs>
            </Box>
          )}
          {filtered !== undefined && filtered.length === 0 && (
            <NoResultsView
              view="no-results"
              title={filter ? `No ${filter} entries found` : 'No entries found'}
              message={
                filter
                  ? 'No entries found that match the filter'
                  : 'We could not find any entries for this series and qualification'
              }
            />
          )}
          {filtered !== undefined && filtered.length > 0 && (
            <>
              <Box display="flex" my="1rem">
                <Box ml="auto">
                  <SearchInput
                    value={searchQuery}
                    placeholder="Search for a centre name or number"
                    onChange={handleSearch}
                    onClear={() => history.push(`/session/${id}/qualification/${qid}`)}
                  />
                </Box>
              </Box>
              {debouncedQuery && tableData?.length === 0 ? (
                <NoResultsView
                  title={`No results found for ‘${debouncedQuery}’`}
                  message={`We couldn’t find a match for ‘${debouncedQuery}’. 
                  Please try another search.`}
                  view="no-search"
                >
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => history.push(`/session/${id}/qualification/${qid}`)}
                  >
                    Reset the search
                  </Button>
                </NoResultsView>
              ) : (
                <>
                  <Table
                    title={filterLabel}
                    columns={headerColumns}
                    data={tableData}
                    rowsPerPage={10}
                    data-testid="marks-table"
                  />
                  {selectedKey && (
                    <ReportViewerModal
                      downloadFileName={selectedKey.fileName}
                      reportKey={selectedKey.key}
                      isOpen={showViewer}
                      toggle={toggle}
                    />
                  )}
                  {selectedCentre && (
                    <DialogWindow
                      description="Please confirm you wish to run centre reports"
                      onCloseDialog={handleCloseOneCentre}
                      openDialog={openConfirmOneCentreModal}
                      title="Confirm"
                      ConfirmButtonOnClick={async () => {
                        handleCloseOneCentre();
                        await generateReportTask.start({ data: { centres: [selectedCentre] } });
                      }}
                      data-testid="DialogConfirmOneCentre"
                      buttonDataTestId={generateReportTask.result?.data.executionArn}
                    />
                  )}
                  {selectedCentre && (
                    <DialogWindow
                      description={`Please confirm which subjects to be on hold or released for centre ${selectedCentre}:`}
                      onCloseDialog={handleCloseHoldOrRelease}
                      openDialog={holdOrReleaseModal}
                      title="Holds update"
                      ConfirmButtonOnClick={async () => {
                        await confirmHoldCompleteModal();
                      }}
                      data-testid="DialogConfirmHold"
                      disableButton={!Object.keys(holdOrReleaseUpdates).length}
                      buttonDataTestId="confirmHoldButton"
                    >
                      {getCentreHoldTask.result?.data ? (
                        <HoldOptions
                          assessmentsHold={getCentreHoldTask.result?.data.map((assessment) => ({
                            assessmentCode: assessment.assessmentCode,
                            assessmentName: assessment.assessmentName,
                            held: assessment.isWholeSubjectOnHold
                              ? 'All'
                              : assessment.heldLearners?.length,
                          }))}
                          onClick={(option: ChangeEvent<HTMLInputElement>) => {
                            const input: string[] = option.target.value.split('-');
                            setHoldOrReleaseUpdates({
                              ...holdOrReleaseUpdates,
                              [input[0]]: input[1],
                            });
                          }}
                          selectedValues={holdOrReleaseUpdates}
                        />
                      ) : (
                        <Loader />
                      )}
                    </DialogWindow>
                  )}
                  {selectedCentre && (
                    <DialogWindow
                      description={
                        holdTask.result?.data
                          ? `Holds have been updated for centre ${selectedCentre}`
                          : `Updating holds for centre ${selectedCentre}...`
                      }
                      onCloseDialog={handleCloseHoldComplete}
                      openDialog={holdCompleteModal}
                      title="Holds update"
                      ConfirmButtonOnClick={() => {
                        handleCloseHoldComplete();
                      }}
                      data-testid="DialogConfirmHoldComplete"
                      disableButton={!holdTask.result?.data}
                      buttonDataTestId="confirmHoldCompleteButton"
                    >
                      {holdTask.result?.data ? <></> : <Loader />}
                    </DialogWindow>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </Box>
    </>
  );
};
