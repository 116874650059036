import React, { useMemo, useState } from 'react';
import {
  DialogContent,
  DialogActions,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { Document, Page } from 'react-pdf';
import { GetApp } from '@material-ui/icons';
import { PageLoader } from '../PageLoader';
import { Error } from '../NoResultsView/NoResultsView';
import { StyledPagination } from '../Table/Table.styles';
import DownloadS3FileButton from '../DownloadS3FileButton';

interface ReportViewerProps {
  reportKey: string;
  downloadFileName: string;
  onClose?: () => void;
  onLoaded: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      zIndex: 1000,
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),
      color: theme.palette.grey[600],
    },
  })
);

export const ReportViewer: React.FC<ReportViewerProps> = ({
  onClose,
  onLoaded,
  reportKey,
  downloadFileName,
}) => {
  const config = useApplicationEnv();
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();
  const onDocLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setPageCount(numPages);
    if (onLoaded) {
      onLoaded();
    }
  };

  const getReportUrlMemo = useMemo(() => {
    if (!config || !reportKey) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/reports`,
      params: { key: reportKey },
    };
  }, [config]);

  const getReportUrlTask = useAsyncTaskAxios<AxiosResponse<{ url: string }>>(
    axios,
    getReportUrlMemo
  );
  const handleChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPageNumber(value);
  };

  useAsyncRun(config && getReportUrlTask);
  const artefactSource = useMemo(() => getReportUrlTask.result?.data.url, [
    getReportUrlTask.result,
  ]);
  return (
    <>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      {getReportUrlTask.started && getReportUrlTask.pending && <PageLoader />}
      {getReportUrlTask.error && <Error />}
      {artefactSource && (
        <>
          <DialogContent>
            <Document
              renderMode="svg"
              file={artefactSource}
              onLoadSuccess={onDocLoadSuccess}
              error={<Error message="Failed to load report" />}
              loading={<PageLoader message="Loading report ..." />}
            >
              <Page width={552} className="mx-auto" pageNumber={pageNumber} />
            </Document>
          </DialogContent>
          {pageCount > 0 && (
            <DialogActions>
              {reportKey && (
                <DownloadS3FileButton s3key={reportKey} downloadFileName={downloadFileName}>
                  <GetApp />
                </DownloadS3FileButton>
              )}
              <StyledPagination
                count={Math.ceil(pageCount)}
                page={pageNumber}
                onChange={handleChange}
              />
            </DialogActions>
          )}
        </>
      )}
    </>
  );
};
