import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { StyledSelect, StyledMenuItem, SelectContainer, SelectLabel } from './Select.styles';
import ChevronDown from '../../common/images/chevron-down';

const useStyles = makeStyles({
  select: {
    '& ul': {
      padding: '0',
    },
  },
});

interface SelectProps {
  label: string;
  value: string | number | unknown;
  options: {
    value: string | number;
    label: string;
    disabled?: boolean;
  }[];
  onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  'data-testid'?: string;
}

const Select = ({
  label,
  options,
  value,
  onChange,
  'data-testid': dataTestId,
}: SelectProps): JSX.Element => {
  const classes = useStyles();

  return (
    <SelectContainer data-testid={dataTestId}>
      <SelectLabel id="label">{label}</SelectLabel>
      <div>
        <StyledSelect
          IconComponent={({ className, ...rest }: { className: string }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ChevronDown {...rest} className={`${className} Chevron-down`} />
          )}
          data-testid="select"
          disableUnderline
          MenuProps={{
            classes: { paper: classes.select },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          labelId="label"
          value={String(value)}
          onChange={onChange}
        >
          {options.map((item) => (
            <StyledMenuItem key={item.value} disabled={item.disabled} value={String(item.value)}>
              {item.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </div>
    </SelectContainer>
  );
};

Select.defaultProps = {
  'data-testid': '',
};

export default Select;
