import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Filter, CentreStatsSummary } from '@cambridgeassessment/checkpoint-dtos';
import { FilterTabLabel } from './Tabs/FilterTabLabel';

export interface SessionQualStatProps {
  filter?: Filter;
  displayLabel: string;
  sessionId: number;
  qualificationId: number;
  stats: CentreStatsSummary;
}

export const SessionQualStat: React.FC<SessionQualStatProps> = ({
  filter,
  displayLabel,
  sessionId,
  qualificationId,
  stats,
}): JSX.Element => (
  <Box mr="3rem">
    <FilterTabLabel
      label={displayLabel}
      primaryCount={stats.centreCount}
      secondaryCount={stats.entryCount}
    />
    <Link
      component={RouterLink}
      to={`/session/${sessionId}/qualification/${qualificationId}${
        filter ? `?filter=${filter}` : ''
      }`}
    >
      <Typography color="inherit" variant="subtitle2" gutterBottom>
        View
      </Typography>
    </Link>
  </Box>
);
