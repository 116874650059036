/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';
import { Menu, MenuItem, Box, Typography } from '@material-ui/core';
import { ButtonLink } from '../Table/Table.styles';

const MoreActionMenu: React.FC<{
  onGenerateReport: () => void;
  onHoldOrRelease: () => void;
  reportGeneratorRunning?: boolean;
}> = ({ onGenerateReport, onHoldOrRelease, reportGeneratorRunning }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(null);

  const handleMenuClickOpen = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <ButtonLink
        onClick={handleMenuClickOpen}
        variant="button"
        underline="none"
        color="primary"
        data-testid="actions-select"
      >
        <Box display="flex" alignItems="center">
          <Typography color="inherit" variant="subtitle2">
            More actions
          </Typography>
          <ExpandMoreSharpIcon />
        </Box>
      </ButtonLink>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          disabled={reportGeneratorRunning}
          onClick={() => onGenerateReport()}
          data-testid="generateOneReport"
        >
          Generate reports
        </MenuItem>
        <MenuItem onClick={() => onHoldOrRelease()} data-testid="holdCentre">
          Hold/release subjects
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MoreActionMenu;
