import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link as RouterLink, useParams, useLocation, useHistory } from 'react-router-dom';
import { orderBy, uniq } from 'lodash';
import { useDebounce } from 'use-lodash-debounce';
import { Box, Container, Link, Typography, Tabs } from '@material-ui/core';
import { getQualification } from '@cambridgeassessment/checkpoint-utils';
import { Breadcrumbs, Button } from '@cambridgeassessment/cambridge-ui';
import {
  Session,
  ExtendedFilter,
  LearnerMark,
  AssessmentMark,
} from '@cambridgeassessment/checkpoint-dtos';
import SearchInput from '../components/SearchInput/SearchInput';
import NoResultsView, { Error } from '../components/NoResultsView/NoResultsView';
import { PageLoader } from '../components/PageLoader';
import Table, { TableDataRow } from '../components/Table/Table';
import { FilterTab } from '../components/Tabs/FilterTab';
import { FilterTabLabel } from '../components/Tabs/FilterTabLabel';
import Select from '../components/Select/Select';
import { useLearnerStats } from '../utils/useLearnerStats';
import { contains } from '../utils/helpers';

export interface SessionCentreQualPath {
  id: string;
  qid: string;
  cid: string;
}

export const CentreOverviewPage = ({ sessions }: { sessions: Session[] }): JSX.Element => {
  const { id, qid, cid } = useParams<SessionCentreQualPath>();
  const [subject, setSubject] = useState<string>();
  const location = useLocation();
  const history = useHistory();

  const useQuery = (): URLSearchParams => new URLSearchParams(location.search);
  const query = useQuery();

  const currentSession = useMemo(
    () => sessions.find((session) => String(session.id) === id)?.displayName,
    [id, sessions]
  );

  const searchQuery = query.get('search') || '';
  const debouncedQuery = useDebounce(searchQuery, 300);

  const handleSearch = (value: string): void => {
    const url = `/session/${id}/qualification/${qid}/centre/${cid}`;
    if (value) {
      history.push(`${url}?search=${value}`);
    } else {
      history.push(`${url}`);
    }
  };

  const filter = useMemo<ExtendedFilter | undefined>(() => {
    const filterQuery = query.get('filter');

    if (!filterQuery) {
      return undefined;
    }

    if (Array.isArray(filterQuery)) {
      return filterQuery[0] as ExtendedFilter;
    }

    return filterQuery as ExtendedFilter;
  }, [location]);

  const { assessmentMarks, task } = useLearnerStats({
    sessionId: id,
    qualificationId: qid,
    centreId: cid,
  });

  const filterRules = useMemo(
    () => ({
      complete: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => !Object.values(item.marks).some((value) => !value)),
      incomplete: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => Object.values(item.marks).some((value) => !value)),
      missing: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => Object.values(item.marks).some((value) => value === 'M')),
      absent: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => Object.values(item.marks).some((value) => value === 'A')),
    }),
    []
  );

  const getUniqueLearners = useCallback(
    (
      assessments: AssessmentMark[],
      callback: (learnerMarks: LearnerMark[]) => LearnerMark[]
    ): number =>
      assessments?.reduce(
        (a, c) =>
          uniq([...a, ...callback(c.learnerMarks).map((learner) => learner.candidateNumber)]),
        [] as number[]
      ).length || 0,
    []
  );

  const filters = useMemo(
    () => [
      {
        value: 0 as const,
        label: 'All learners',
        count:
          assessmentMarks?.reduce(
            (a, c) => (a > c.learnerMarks.length ? a : c.learnerMarks.length),
            0
          ) || 0,
        entryCount: assessmentMarks?.reduce((a, c) => a + c.learnerMarks.length, 0) || 0,
      },
      {
        value: 'complete' as ExtendedFilter,
        label: 'Complete learners',
        count:
          (assessmentMarks?.reduce(
            (a, c) => (a > c.learnerMarks.length ? a : c.learnerMarks.length),
            0
          ) || 0) - getUniqueLearners(assessmentMarks || [], filterRules.incomplete),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.complete(c.learnerMarks).length, 0) ||
          0,
      },
      {
        value: 'incomplete' as ExtendedFilter,
        label: 'Incomplete learners',
        count: getUniqueLearners(assessmentMarks || [], filterRules.incomplete),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.incomplete(c.learnerMarks).length, 0) ||
          0,
      },
      {
        value: 'missing' as ExtendedFilter,
        label: 'Missing entries',
        count: getUniqueLearners(assessmentMarks || [], filterRules.missing),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.missing(c.learnerMarks).length, 0) || 0,
      },
      {
        value: 'absent' as ExtendedFilter,
        label: 'Absent learners',
        count: getUniqueLearners(assessmentMarks || [], filterRules.absent),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.absent(c.learnerMarks).length, 0) || 0,
      },
    ],
    [assessmentMarks]
  );

  const subjects = useMemo(
    () =>
      orderBy(
        assessmentMarks?.map(({ assessmentCode, assessmentName }) => ({
          value: assessmentCode,
          label: assessmentName,
        })),
        ['label'],
        ['asc']
      ) || [],
    [assessmentMarks]
  );

  useEffect(() => {
    if (subjects && subjects.length) {
      setSubject(subjects[0].value);
    }
  }, [subjects]);

  const filterLearners = useCallback(
    (learnerMarks: LearnerMark[], filterValue?: ExtendedFilter): LearnerMark[] => {
      switch (filterValue) {
        case 'complete':
          return filterRules.complete(learnerMarks);
        case 'incomplete':
          return filterRules.incomplete(learnerMarks);
        case 'missing':
          return filterRules.missing(learnerMarks);
        case 'absent':
          return filterRules.absent(learnerMarks);
        default:
          return learnerMarks;
      }
    },
    []
  );

  const tableData = useMemo(() => {
    const subjectLearners =
      assessmentMarks?.find((item) => item.assessmentCode === subject)?.learnerMarks || [];

    return filterLearners(subjectLearners, filter)
      .filter(
        (item) =>
          contains(item.name, debouncedQuery) ||
          contains(String(item.candidateNumber), debouncedQuery)
      )
      .map((item) => ({
        ...item,
        ...Object.keys(item.marks).reduce(
          (a, c) => ({
            ...a,
            [`component${c}`]: item.marks[c],
          }),
          {}
        ),
      }));
  }, [assessmentMarks, subject, location, debouncedQuery]);

  const headerColumns = useMemo(
    () => [
      {
        header: 'Learner name',
        accessor: 'name',
      },
      {
        header: 'No.',
        accessor: 'candidateNumber',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Teaching Group',
        accessor: 'teachingGroupNumber',
        cellProps: {
          align: 'center' as const,
        },
      },
      ...Object.keys(
        tableData.reduce(
          (a, c) => ({
            ...a,
            ...Object.keys(c)
              .filter((key) => key.includes('component'))
              .reduce(
                (acc, current) => ({
                  ...acc,
                  [current]: '',
                }),
                {}
              ),
          }),
          {}
        )
      ).map((key) => ({
        header: `Component ${key.slice(-1)}`,
        accessor: key,
        renderCell: (row: TableDataRow) => {
          switch (row[key]) {
            case 'A':
              return 'Absent';
            case 'M':
              return 'Missing';
            case null:
              return '';
            default:
              return row[key] as string;
          }
        },
        cellProps: {
          align: 'center' as const,
        },
      })),
      {
        header: 'Compliance',
        accessor: 'compliance',
        cellProps: {
          align: 'center' as const,
        },
      },
    ],
    [tableData]
  );

  return (
    <>
      <Box py={1.5} borderBottom={`4px solid ${getQualification(parseInt(qid, 10)).color}`}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h4" gutterBottom>
                {cid}
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" gutter="sm">
                <Link component={RouterLink} to={`/session/${id}`}>
                  <span>{currentSession}</span>
                </Link>
                <Link component={RouterLink} to={`/session/${id}/qualification/${qid}`}>
                  <span>{getQualification(parseInt(qid, 10)).name}</span>
                </Link>
                <Typography>{cid}</Typography>
              </Breadcrumbs>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        {task.started && task.pending && <PageLoader />}
        {task.error && <Error />}
        {!!assessmentMarks && (
          <Container maxWidth="lg">
            <Box mt="1rem" mb="2rem">
              <Tabs
                value={filter || 0}
                onChange={(
                  _event: React.ChangeEvent<Record<string, unknown>>,
                  newValue: ExtendedFilter
                ) => {
                  history.push(
                    newValue
                      ? `/session/${id}/qualification/${qid}/centre/${cid}?filter=${newValue}`
                      : `/session/${id}/qualification/${qid}/centre/${cid}`
                  );
                }}
                indicatorColor="primary"
                textColor="primary"
                data-testid="filter-tabs"
              >
                {filters.map((item) => (
                  <FilterTab
                    value={item.value}
                    label={
                      <FilterTabLabel
                        label={item.label}
                        primaryCount={item.count}
                        secondaryCount={item.entryCount}
                      />
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Box display="flex" my="1rem" alignItems="center">
              <Box pt="0.5rem">
                <Select
                  label="Subject:"
                  options={subjects}
                  onChange={(e) => setSubject(String(e.target.value))}
                  value={subject}
                  data-testid="subject-select"
                />
              </Box>
              <Box ml="auto">
                <SearchInput
                  value={searchQuery}
                  placeholder="Search for a learner name or number"
                  onChange={handleSearch}
                  onClear={() => history.push(`/session/${id}/qualification/${qid}/centre/${cid}`)}
                />
              </Box>
            </Box>
            {debouncedQuery && tableData?.length === 0 ? (
              <NoResultsView
                title={`No results found for ‘${debouncedQuery}’`}
                message={`We couldn’t find a match for ‘${debouncedQuery}’. 
              Please try another search.`}
                view="no-search"
              >
                <Button
                  color="primary"
                  size="small"
                  onClick={() => history.push(`/session/${id}/qualification/${qid}/centre/${cid}`)}
                >
                  Reset the search
                </Button>
              </NoResultsView>
            ) : (
              <Table
                title={
                  assessmentMarks.find((item) => subject === item.assessmentCode)?.assessmentName ||
                  ''
                }
                columns={headerColumns}
                data={tableData}
                rowsPerPage={10}
                data-testid="learners-table"
              />
            )}
          </Container>
        )}
      </Box>
    </>
  );
};
