import { Filter, CentreStats, CentreStatsSummary } from '@cambridgeassessment/checkpoint-dtos';
import { mapValues, sumBy } from 'lodash';

export const filterPredicates: { [key in Filter]: (data: CentreStats) => boolean } = {
  complete: (x: CentreStats) => x.completeCount === x.entryCount,
  incomplete: (x: CentreStats) => x.incompleteCount > 0,
  missing: (x: CentreStats) => x.missingCount > 0,
  absent: (x: CentreStats) => x.absentCount > 0,
};

export const filterFields: { [key in Filter]: (data: CentreStats) => number } = {
  complete: (x: CentreStats) => x.completeCount,
  incomplete: (x: CentreStats) => x.incompleteCount,
  missing: (x: CentreStats) => x.missingCount,
  absent: (x: CentreStats) => x.absentCount,
};

export const groupCentreStats = (
  centreStats: CentreStats[]
): { [key in Filter]: CentreStatsSummary } | null => {
  if (centreStats.length === 0) {
    return null;
  }
  const mapped = mapValues(filterPredicates, (value, key) => {
    return {
      centreCount: centreStats.filter(value).length,
      entryCount: sumBy(centreStats.filter(value), (z) => filterFields[key as Filter](z)),
    };
  });

  return mapped as { [key in Filter]: CentreStatsSummary };
};
