import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router';
import { Box } from '@material-ui/core';
import { Session } from '@cambridgeassessment/checkpoint-dtos';
import { useApplicationEnv } from 'application.env/react';
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async';
import axios, { AxiosResponse } from 'axios';
import { useAuth } from 'oidc-react';
import { useAxiosInterceptors } from './utils/useAxiosInterceptor';

// Pages
import { SessionOverviewPage } from './pages/session-overview-page';
import { SessionQualificationProgressPage } from './pages/session-qualification-progress-page';
import { CentreOverviewPage } from './pages/centre-overview-page';

// Components
import { Nav } from './components/nav';
import { PageLoader } from './components/PageLoader';
import { Error } from './components/NoResultsView/NoResultsView';

export const RoutesWithHeader = (): JSX.Element => {
  const config = useApplicationEnv();
  const auth = useAuth();
  const interceptorConfigured = useAxiosInterceptors();
  const getSessionsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions`,
    };
  }, [config, auth]);
  const getSessionsTask = useAsyncTaskAxios<AxiosResponse<Session[]>>(axios, getSessionsMemo);

  useAsyncRun(auth && auth.userData && interceptorConfigured && getSessionsTask);
  return (
    <Box mb={1}>
      <Nav />
      {getSessionsTask.started && getSessionsTask.pending && <PageLoader />}
      {getSessionsTask.error && <Error />}
      {getSessionsTask.result && (
        <Switch>
          <Route exact path="/">
            <SessionOverviewPage sessions={getSessionsTask.result.data} />
          </Route>
          <Route exact path="/session/:id">
            <SessionOverviewPage sessions={getSessionsTask.result.data} />
          </Route>
          <Route exact path="/session/:id/qualification/:qid">
            <SessionQualificationProgressPage sessions={getSessionsTask.result.data} />
          </Route>
          <Route exact path="/session/:id/qualification/:qid/centre/:cid">
            <CentreOverviewPage sessions={getSessionsTask.result.data} />
          </Route>
        </Switch>
      )}
    </Box>
  );
};
