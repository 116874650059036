import { useMemo } from 'react';
import { useAsyncTaskAxios, useAsyncRun, AsyncTask } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { AssessmentMark } from '@cambridgeassessment/checkpoint-dtos';

interface LearnerStatsProps {
  sessionId: string;
  qualificationId: string;
  centreId: string;
}

interface LearnerStatsData {
  task: AsyncTask<AxiosResponse<AssessmentMark[]>, [] | [unknown]>;
  assessmentMarks?: AssessmentMark[];
}

export const useLearnerStats = (sesQualProps: LearnerStatsProps): LearnerStatsData => {
  const config = useApplicationEnv();

  const { sessionId, qualificationId, centreId } = sesQualProps;

  const getLearnerStatsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/centres/${centreId}`,
    };
  }, [config]);

  const getLearnerStatsTask = useAsyncTaskAxios<AxiosResponse<AssessmentMark[]>>(
    axios,
    getLearnerStatsMemo
  );

  useAsyncRun(config && getLearnerStatsTask);

  return {
    task: getLearnerStatsTask,
    assessmentMarks: getLearnerStatsTask?.result?.data,
  };
};
