import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import { colours } from '@cambridgeassessment/cambridge-ui';
import { Filter, ExtendedFilter } from '@cambridgeassessment/checkpoint-dtos';

interface FilterTabProps {
  label?: React.ReactNode;
  value: Filter | ExtendedFilter | 0;
}

export const FilterTab = withStyles(
  () =>
    createStyles({
      root: {
        textTransform: 'none',
        borderBottom: `2px solid ${colours.monochromeLight}`,
      },
      wrapper: {
        alignItems: 'flex-start',
      },
    })
  // eslint-disable-next-line react/jsx-props-no-spreading
)((props: FilterTabProps) => <Tab {...props} />);
