import React, { useState, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { orderBy } from 'lodash';
import {
  Table as MaterialTable,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Typography,
  TableCellProps,
  TableSortLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from './TablePagination';
import { Container, HeaderCell, BodyCell } from './Table.styles';

export type TableDataRow = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type TableData = TableDataRow[];

type Order = undefined | 'asc' | 'desc';

interface TableProps {
  title: string;
  rowsPerPage: number;
  columns: {
    header: string;
    accessor: string;
    renderCell?: (row: TableDataRow) => JSX.Element | string;
    cellProps?: TableCellProps;
  }[];
  data?: TableData;
  'data-testid'?: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Table = ({
  title,
  columns,
  data,
  rowsPerPage,
  'data-testid': dataTestId,
}: TableProps): JSX.Element => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [sortOptions, setSort] = useState<{ column: string; order: Order }>();

  const tableData = useMemo<TableData>((): TableData => {
    if (sortOptions && sortOptions.column) {
      return orderBy(data, [sortOptions.column], [sortOptions.order ? sortOptions.order : false]);
    }
    return data || [];
  }, [data, sortOptions]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    if (tableData && value <= Math.ceil(tableData.length / rowsPerPage)) {
      setPage(value);
    }
  };

  const currentPage = useMemo(
    () => tableData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage),
    [tableData, page, rowsPerPage]
  );

  useEffect(() => {
    setPage(1);
  }, [tableData]);

  const handleSort = (event: React.MouseEvent<unknown>, column: string): void =>
    setSort({
      column,
      order:
        sortOptions && sortOptions.column === column && sortOptions.order === 'asc'
          ? ('desc' as Order)
          : ('asc' as Order),
    });

  return (
    <TableContainer component={Container}>
      <Typography variant="h4">{title}</Typography>
      <MaterialTable className={classes.table} aria-label="table" data-testid={dataTestId}>
        <TableHead>
          <TableRow>
            {columns.map(({ header, accessor, cellProps }) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <HeaderCell key={uuid()} {...cellProps} onClick={(e) => handleSort(e, accessor)}>
                <TableSortLabel
                  active={sortOptions?.column === accessor}
                  direction={sortOptions?.order}
                >
                  {header}
                </TableSortLabel>
              </HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData &&
            currentPage.map((row) => (
              <TableRow key={uuid()}>
                {columns.map(({ accessor, cellProps, renderCell }) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <BodyCell key={uuid()} {...cellProps}>
                    {renderCell ? renderCell(row) : row[accessor]}
                  </BodyCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </MaterialTable>
      {tableData && !!tableData.length && (
        <TablePagination
          count={tableData ? tableData.length / rowsPerPage : 0}
          page={page}
          handleChange={handleChange}
        />
      )}
    </TableContainer>
  );
};

Table.defaultProps = {
  data: [],
  'data-testid': '',
};

export default Table;
