import React, { useMemo } from 'react';
import { Box, LinearProgress, Typography, CircularProgress } from '@material-ui/core';

interface ProgressBarProps {
  increment: number;
  label: string;
  subLabel: string;
  total?: number;
}

const ProgressBar = ({ increment, label, subLabel, total }: ProgressBarProps): JSX.Element => {
  const progressPercentage = useMemo(() => {
    if (total) return (increment * 100) / total;

    return 0;
  }, [increment, total]);
  return (
    <Box alignSelf="center" mr={1}>
      <Box display="flex">
        <Box minWidth={35} mb={1}>
          <Typography variant="subtitle1" gutterBottom>
            {label}
          </Typography>
        </Box>
        <Box ml="auto">
          <Box display="flex" alignItems="center">
            <Box mr={1} textAlign="right">
              {total !== undefined && total > 0 && (
                <>
                  <Typography variant="caption" color="textSecondary">
                    {subLabel}
                  </Typography>
                  <Typography
                    variant="caption"
                    className="semi-bold"
                    display="block"
                  >{`${increment} / ${total}`}</Typography>
                </>
              )}
            </Box>
            <CircularProgress size="24px" />
          </Box>
        </Box>
      </Box>
      <Box minWidth={280}>
        <LinearProgress variant="determinate" value={progressPercentage} />
      </Box>
    </Box>
  );
};

ProgressBar.defaultProps = {
  total: 0,
};

export default ProgressBar;
