import {
  getAverage,
  getAverageByTeachingGroup,
  getAverageScore,
  getGroupedAverages,
  getLearnersPerAssessment,
  getStrandAverageForEachSubject,
  getLearnersWithAssessmentResults,
  getStrandScoreAverage,
  groupByAge,
  groupScoresByAge,
  groupAgesByScore,
  groupLearnersByScore,
  getAge,
} from './aggregates';

import { getQualification, qualifications } from './qualificationMapper';
import { groupCentreStats, filterPredicates, filterFields } from './centreStatsGrouper';
import { axiosHeaders } from './middlewares/axios-headers';
import { InvalidInputError, NotFoundError } from './middlewares/customErrors';

export {
  axiosHeaders,
  getAge,
  getAverage,
  getAverageByTeachingGroup,
  getAverageScore,
  getGroupedAverages,
  getLearnersPerAssessment,
  getQualification,
  getStrandAverageForEachSubject,
  getLearnersWithAssessmentResults,
  getStrandScoreAverage,
  groupByAge,
  groupScoresByAge,
  groupAgesByScore,
  groupLearnersByScore,
  groupCentreStats,
  filterPredicates,
  filterFields,
  InvalidInputError,
  NotFoundError,
  qualifications,
};
