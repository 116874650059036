import middy from '@middy/core';
import { log } from '@cambridgeassessment/aws-lambda-logging';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { APITimeoutError } from './customErrors';

export const isTimeout = (axiosResponse: AxiosResponse | undefined): boolean =>
  !!axiosResponse && axiosResponse.status === 408;

function isAxiosError(error: any): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

export const axiosHeaders: middy.Middleware<void, any, any> = (): middy.MiddlewareObject<
  any,
  any
> => ({
  before: (handler, next) => {
    axios.interceptors.request.use((request) => {
      if (request.url && request.url.startsWith(process.env.CXAPI_DOMAIN!)) {
        try {
          const clientId = process.env.CXAPI_CLIENT_ID;
          const clientSecret = process.env.CXAPI_CLIENT_SECRET;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          request.headers = {
            ...request.headers,
            'x-transaction-id': handler.context.awsRequestId,
            client_id: clientId,
            client_secret: clientSecret,
          };
        } catch (e) {
          log.error(e);
          throw e;
        }
      }
      return request;
    });
    return next();
  },
  onError: (handler, next) => {
    if (isAxiosError(handler.error) && isTimeout(handler.error.response)) {
      const timeoutError = new APITimeoutError(handler.error.message);
      return next(timeoutError);
    }
    return next(handler.error);
  },
});
export default axiosHeaders;
