import { useMemo } from 'react';
import { useAsyncTaskAxios, AsyncTask, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { sumBy } from 'lodash';
import { CentreStats, Filter, CentreStatsSummary } from '@cambridgeassessment/checkpoint-dtos';
import { groupCentreStats, filterPredicates } from '@cambridgeassessment/checkpoint-utils';

interface CentreStatsProps {
  sessionId: string;
  qualificationId: string;
  filter?: Filter;
}

interface CentreStatsData {
  task: AsyncTask<AxiosResponse<CentreStats[]>, [] | [unknown]>;
  filtered?: CentreStats[];
  totalCount?: number;
  totalEntryCount?: number;
  centreSummaryStats?: { [key in Filter]: CentreStatsSummary } | null;
}
export const useCentreStats = (seshQualProps: CentreStatsProps): CentreStatsData => {
  const config = useApplicationEnv();

  const { sessionId, qualificationId, filter } = seshQualProps;

  const getCentreStatsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}`,
    };
  }, [config]);

  const getCentreStatsTask = useAsyncTaskAxios<AxiosResponse<CentreStats[]>>(
    axios,
    getCentreStatsMemo
  );
  useAsyncRun(config && getCentreStatsTask);
  const filtered = useMemo(() => {
    if (!getCentreStatsTask.result) {
      return undefined;
    }
    if (!filter) {
      return getCentreStatsTask.result.data;
    }
    const pred = filterPredicates[filter];

    return getCentreStatsTask.result.data.filter(pred);
  }, [getCentreStatsTask.result, filter]);

  const centreSummaryStats = useMemo(() => {
    if (!getCentreStatsTask.result) {
      return undefined;
    }
    return groupCentreStats(getCentreStatsTask.result.data);
  }, [getCentreStatsTask.result, filter]);

  return {
    task: getCentreStatsTask,
    totalCount: getCentreStatsTask?.result?.data.length,
    totalEntryCount: sumBy(getCentreStatsTask?.result?.data, (x) => x.entryCount),
    filtered,
    centreSummaryStats,
  };
};
