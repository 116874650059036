import React, { useMemo } from 'react';
import { CircularProgress, IconButton, Box } from '@material-ui/core';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { useDownloadFile } from '../use-download-file';

export interface DownloadS3FileButtonProps {
  s3key: string;
  downloadFileName: string;
}

const DownloadS3FileButton: React.FC<DownloadS3FileButtonProps> = ({
  s3key,
  downloadFileName,
  children,
}): JSX.Element => {
  const config = useApplicationEnv();

  const getPresignedUrlMemo = useMemo(() => {
    if (!config || !s3key) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/reports`,
      params: { key: s3key },
    };
  }, [config, s3key]);

  const getPresignedUrlTask = useAsyncTaskAxios<AxiosResponse<{ url: string }>>(
    axios,
    getPresignedUrlMemo
  );

  const downloadReportTask = useDownloadFile({
    fileUrl: getPresignedUrlTask.result?.data.url || '',
    fileName: downloadFileName,
  });

  const pending = useMemo(
    () =>
      (getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
      (getPresignedUrlTask.result != null &&
        downloadReportTask.pending &&
        downloadReportTask.started),
    [
      getPresignedUrlTask.pending,
      getPresignedUrlTask.started,
      getPresignedUrlTask.result,
      downloadReportTask.pending,
      downloadReportTask.started,
    ]
  );

  useAsyncRun(getPresignedUrlTask.result && downloadReportTask);

  return (
    <>
      {pending && (
        <Box m="12px" display="flex" alignItems="center">
          <CircularProgress size={24} />
        </Box>
      )}
      {!pending && (
        <>
          <IconButton onClick={() => getPresignedUrlTask.start()}>{children}</IconButton>
        </>
      )}
    </>
  );
};

export default DownloadS3FileButton;
